@import "../../../assets/styles/variables";
@import "../../../assets/styles/utility";
@import "../../../assets/styles/mixins";
@import "../../../assets/styles/animations";

button.selectedCompliance {
  background-color: $theme-hover;
}

.header {
  padding: 0.6em;
  font-size: 1.4em;
  text-transform: none;
  background-color: $theme-hover;
}

.checklistNumber {
  display: inline-block;
  height: 2em;
  width: 2em;
  line-height: 2em;
  background-color: white;
  border-radius: 50%;
  margin-bottom: 0.85em;
  font-size: 1.4em;

  &::before, &::after {
    content: "";
    position: absolute;
    top: 1em;
    height: 1px;
    background-color: $theme-color;
    width: 0;
  }
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
}

.checklist {
  color: $theme-lite;
  text-align: center;
  position: relative;
  margin-bottom: 3em;

  .checklistNumber::before, .checklistNumber::after {
    width: calc(50% - 1em);
  }

  @media (max-width: 576px) {
    &:nth-child(odd) {
      .checklistNumber::before {
        width: 0;
      }
    }

    &:nth-child(even) {
      .checklistNumber::after {
        width: 0;
      }
    }
  }

  @media (min-width: 576px) {
    &:first-child,
    &:nth-child(3n + 1) {
      .checklistNumber::before {
        width: 0;
      }
    }

    &:nth-child(3n) {
      .checklistNumber::after {
        width: 0;
      }
    }
  }
}

.tileImg {
  width: 100%;
  max-height: 20em;
  object-fit: contain;
}

.imgContainer {
  background-color: $theme-hover;
  padding: 1em;
}

.featureIcons {
  font-size: 0.8em;
}

.featIcon {
  width: 2.5em;
  margin-right: 0.5em;
}

.faqCarousal, .featureList {
  width: 100%;
  flex: 1 1 35%;
  background-color: $theme-color;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding-top: 1.2em;
  color: #fff;
}

@media (min-width: 768px) {
  .featureCarousalContainer {
    display: flex;
  }
  .featureList {
    width: fit-content;
  }
}

.featureCarousal {
  min-width: 1px;
  flex: 1 1 65%;
  padding-top: 1.2em;
  padding-bottom: 1.2em;
  padding-left: min(3vw, 3em);
}

.featureRow {
  @extend .u-flex-centered;
  padding: 1em 0;
  cursor: pointer;
  transition: all 0.2s ease-in;

  h4 {
    margin: 0 0 0 0.5em;
    font-size: 1.25em;
  }

  svg {
    flex: 0 0 20px;
    path {
      fill: #fff;
    }
  }

  &:hover {
    background-color: darken($theme-lite-2, 12);
  }

  &.currentItem {
    background-color: $theme-lite-2;
  }
}

.carousalItem {
  line-height: 1.6;
}

.carousalButton {
  margin-top: 2em;
  height: 4em;
  cursor: pointer;

  span {
    @extend .u-flex-centered;
  }

  svg {
    margin-left: 1em;
    width: 1.5em;

    path {
      stroke: $text-dark;
    }
  }

  &:hover {
    .arrowRight {
      animation: bounceRight 2s 1;
    }
    .arrowLeft {
      animation: bounceLeft 2s 1;
    }
  }

}

.disclaimer {
  @extend .u-text-center;
  @include spacing(padding-left);
  @include spacing(padding-right);
  padding-top: 1em;
  padding-bottom: 1em;
  background-color: $text-dark;
  color: #fff;
  h5 {
    line-height: 1.4;
    margin: 0;
  }
}

.notepadContainer {
  @extend .u-text-center;
  @extend .u-full-width;
  .bg {
    height: 6em;
    width: 6em;
    position: absolute;
    left: 40%;
    bottom: -100%;
    z-index: 0;
    @media (min-width: 768px) {
      left: 0;
      bottom: -30%;
    }
    .notepad {
      height: 5em;
    }
  }

  .demoBtn {
    margin: auto;
    @media (max-width: 768px) {
      width: 100%;
      z-index: 1;
      position: relative;
    }
  }
}

.complianceIcon {
  width: 5em;
}

.para {
  margin: 0 0 1em;
}
