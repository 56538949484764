@import "../../assets/styles/variables";

.iconRight {
  position: absolute;
  left: 50px;
  top: -12px;
  height: 8em;
  width: 8em;
  z-index: -1;
}

.iconLeft {
  width: 10em;
  height: 14em;
  position: absolute;
  bottom: 1em;
  left: -10em;
}

.pcImg {
  height: 18em;
}

.priceColRight {
  @media (min-width: 768px) {
    padding: 0 1em;
  }
}

.tag {
  padding: 0.5em 0.75em;
  border-radius: 4px;
}

.priceTagContainer {
  position: relative;
  font-size: 1.125em;;
}

.content {
  padding: 3em 0;
  background-color: #fff;
}

.thumbnailContainer {
  height: 12em;
  width: 12em;
  position: relative;
  margin: auto;

  img, .path {
    height: 100%;
    width: 100%;
  }

  .memberImg {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    padding: 1.8em;
    border-radius: 50%;
  }
}

.memberContainer {
  h5, h4 {
    margin: 0;
  }
  p {
    text-align: justify;
  }
}

.bannerContainer {
  @media (max-width: 768px) {
    padding-bottom: 2em;
  }
}

.formContainer {
  padding: 1.5em;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
}

.textarea > div {
  font-family: 'halyard-display' !important;
}
