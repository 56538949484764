@import "../../assets/styles/mixins";
@import "../../assets/styles/utility";
@import "../../assets/styles/variables";

.footer {
  @include spacing(padding-left);
  @include spacing(padding-right);
  padding-top: 2em;
  padding-bottom: 2em;
  background-color: $nav-bg;
}

.logo {
  width: 80px;
  height: 80px;
  position: relative;
  z-index: 1;
}

.logoCol {
  padding-right: 1.2em !important;
}

.logoContainer {
  position: relative;
}

.shadow {
  position: absolute;
  height: 1px;
  width: 1px;
  top: 40px;
  left: 40px;
  box-shadow: 0px 0px 20px 32px rgb(0 0 0 / 60%);
  border-radius: 50%;
}

.desc {
  h4 {
    margin: 0.5em 0;
  }
  p {
    margin: 1em 0 0;
    color: $footer-text;
  }
}

.linksContainer {
  height: 100%;
  padding: 0 1.2em;

  @media (min-width: 768px) {
    border-left: 1px solid #fff;
  }

  h4 {
    margin: 0 0 0.8em;
  }

  a {
    color: $footer-text;
    text-decoration: none;
    display: block;
    margin: 0.4em 0;
    cursor: pointer;

    &:hover {
      color: $footer-text;
      text-decoration: underline;
    }
  }

}

.socialMediaIcons {
  display: flex;
  margin-top: 1em;

  svg {
    width: 2em;
    margin-right: 1.6em;

    path {
      stroke: rgba(255, 255, 255, 0.75);
      fill: rgba(255, 255, 255, 0.75);
    }

    &:hover {
      path {
        stroke: rgba(255, 255, 255, 1);
        fill: rgba(255, 255, 255, 1);
      }
    }
  }
}

@media (max-width: 767px) {
  .brandDesc, .logoDesc {
    justify-content: space-around;
  }

  .footer {
    text-align: center;
  }

  .socialMediaIcons {
    width: 100%;
    justify-content: center;
  }
}
