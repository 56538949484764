@import "../../assets/styles/variables";
@import "../../assets/styles/utility";
@import "../../assets/styles/animations";

.wrapper {
  min-width: 18em;
  transition: all 0.2s ease-in-out;
  margin-bottom: 1em;

  .cardContent {
    padding: 1.5em;
    position: relative;
    top: -0.6em;
    background-color: #fff;
    border: 1px solid $border-color;
    border-top-left-radius: 0.6em;
    border-top-right-radius: 0.6em;

    &:hover {
      box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
      background-color: $theme-hover;
    }
  }
}

.heading {
  letter-spacing: 4px;
  padding: 1.1em 1em 1.6em;
  background-color: $grey-bg;
  color: #fff;
  border-top-left-radius: 0.6em;
  border-top-right-radius: 0.6em;
  text-align: center;
}

.popularHeading {
  background-color: $theme-color;
}

.header {
  @extend .u-flex-centered;
  justify-content: space-between;
}

.content {
  padding-left: 0;
  margin-bottom: 2em;

  .featRow {
    @extend .u-flex-centered;
    padding: 0.6em;
    font-size: 1.2em;

    img {
      width: 1em;
      margin-right: 8px;
    }
  }
}

.license {
  margin: 0 0 0.5em 0;
  letter-spacing: 0.25em;

  &+h2 {
    margin: 0;
    font-weight: 600;
    letter-spacing: 2px;
  }
}

.priceWrap {
  @extend .color-theme;
  display: flex;
  font-weight: 600;
  h1 {
    margin: 0;
    font-size: 3.5em;
  }

  .symbol {
    font-size: 2em;
    position: relative;
    top: .2em;
  }
}

.purchaseButton {
  @extend .u-flex-centered;
  @extend .color-theme;
  font-size: 1.15em;
  padding: 0.6em;

  .arrow {
    margin-left: 0.6em;
    path {
      stroke: $theme-color;
    }
  }

  &:hover {
    .arrow {
      animation: bounceRight 2s 1;
    }
  }
}
