@import "../../assets/styles/mixins";
@import "../../assets/styles/variables";

.wrapper {
  position: sticky;
  top: 0;
  z-index: 9;
  margin-top: 10px;
  color: #7450a3;
}
.wrapperScrolling {
  position: sticky;
  top: 0;
  z-index: 9;
  margin-top: 10px;
  background: #7450a3;
  color: #fff;
}

.header {
  @include spacing(padding-left);
  @include spacing(padding-right);
  width: 100%;
  z-index: 12;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.navLogo {
  flex: 1 0 auto;
}

.navLinks {
  flex: 1 1 auto;
  @media (max-width: 1079px) {
    font-size: 0.9em;
  }
}

.LogoImg {
  height: 100%;
}

.navLinks ul {
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  padding-left: 0;
}

.navLinks li {
  display: block;
  text-align: center;
  font-size: 1.1em;

  &:not(:last-child) {
    margin-right: 1em;
  }
}

.navLinks li a:hover {
  &::after {
    visibility: visible;
    width: 100%;
  }
}

.navLinks a {
  text-decoration: none;
  display: block;
  color: inherit;
  padding: 8px 0;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    width: 0;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: $theme-hover;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }
}

.navLinks a:hover {
  text-decoration: none;
}

.navLinks a.home {
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: $theme-hover;
    visibility: visible;
  }
}

.logo {
  height: 100%;
  max-height: 80px;
  text {
    transition: fill 0.2s ease-in-out;
  }
  &.expandedNav {
    text {
      fill: #8765ae;
    }
  }
}

.demobutton {
  @media (min-width: 992px) {
    margin-left: 1em;
  }
}

.homeLink {
  width: 100%;
  display: inherit;
  color: inherit;
  text-decoration: none;
  padding: 7px 0;
}

li.navlinkButton {
  @media (max-width: 768px) {
    padding: 14px 0;
    height: 70px;
  }
}
