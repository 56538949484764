@font-face {
  font-family: "Exo2";
  src: url("../../assets/fonts/Exo2-VariableFont_wght.ttf") format("truetype");
}


@font-face {
  font-family: "Helvetica";
  src: url("../../assets/fonts/Helvetica-Bold.ttf") format("truetype");
}

@font-face {
  font-family:"halyard-display";
  src:url("../../assets/fonts/Halyard.woff2") format("woff2"),url("https://use.typekit.net/af/272149/00000000000000007735adb1/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/272149/00000000000000007735adb1/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:300;
}

.u-font-halyard {
  font-family: 'halyard-display';
}

.u-font-exo {
  font-family: 'Exo2';
}
