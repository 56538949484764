@import "../../assets/styles/variables";
@import "../../assets/styles/utility";
@import "../../assets/styles/mixins";

.toolkitHeader {
  background-color: #9476bc;
  padding: min(3vw, 3em);
  color: #fff;
}

.toolkitContent {
  @media (min-width: 768px) {
    background-image: url("../../assets/icons/features_icon.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
  }
}

.toolkitContainer {
  background-color: #fff;
}

.featureRow {
  @extend .u-flex;
  padding-left: max(5vw, 3em);
  padding-right: 2em;
  align-items: center;
  padding-top: 1.5em;
  padding-right: 1em;
  padding-bottom: 1.5em;
  color: $text-color;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    background-color: lighten($theme-hover, 10);
  }

  @media (min-width: 768px) {
    padding-top: 1em;
    padding-right: 1em;
    padding-bottom: 1em;

    &.currentItem {
      background-color: $theme-hover;
    }
  }

  h4 {
    margin: 0 0 0 8px;
    font-size: 1.25em;
  }

  svg {
    flex: 0 0 20px;
  }

  &.shown {
    svg {
      path {
        fill: $green;
      }
    }
  }
  @media (max-width: 768px) {
    svg {
      path {
        fill: $green;
      }
    }
  }
}

.featureListContainer {
  background: white;
  box-shadow: 0px 0px 12px 0px rgb(97 97 97 / 45%);
  margin: 2em;
  flex: 0 0 25em;
  padding-top: 1px;

  @media (min-width: 768px) {
    position: relative;
    top: -2em;
    border-top-right-radius: 2em;
    padding: 0;
    margin: 0;
  }

  h2 {
    @extend .color-theme;
  }
}

.grid {
  position: relative;
  @media (min-width: 768px) {
    display: flex;
  }
}

.gridRight {
  padding: 0 2em;
  flex: 1;
  max-width: calc(100% - 25em);
}

.para {
  font-size: 1.25em;
  text-align: left;
  letter-spacing: $letter-spacing;
}

.paraBottom {
  font-size: 1.25em;
  text-align: center;
  letter-spacing: $letter-spacing;

  li {
    text-align: left;
    list-style: disc;
    font-size: 0.8em;
  }
}

.screenshot {
  max-height: 30em;
  max-width: 100%;
  object-fit: contain;
  margin-bottom: 2em;
}

.slideButton {
  padding: 0.8em 2.5em;
  margin: auto;
}

.paddingLeft {
  padding-left: max(5vw, 3em);
  height: 1.25em;
}

.featureTitle {
  display: flex;
  align-items: flex-start;
}

.heading {
  word-break: break-word;
}

.anchorstyle {
  text-decoration: none;
}
