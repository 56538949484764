.masonryWrapper {
  width: 100% !important;
}
.newscard {
  position: relative;
  padding: 0.75rem;
  margin: 0.5rem;
  border: 1px solid lightgray;
  border-radius: 10px;
  .flexContainer {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: center;
  }

  .flexItem:nth-child(1) {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
  }

  .flexItem:nth-child(2) {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 2 1 auto;
    -ms-flex: 2 1 auto;
    flex: 2 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    padding-left: 1rem;
    max-width: 80%;

    h4 {
      margin: 0 0 0.5rem 0;
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  p {
    text-align: justify;
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
  }

  img {
    max-height: 75px;
    min-height: 75px;
    max-width: 100%;
    border-radius: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  }

  .optionButton {
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    padding: 0 1rem 0 0;
    bottom: 0;
    right: 0;
    cursor: pointer;

    img {
      min-height: 0;
      width: 1.6em;
      box-shadow: none;
    }
  }
}
.card {
  padding: 1em;
  box-shadow: 0px 0px 6px 0px rgb(97 97 97 / 45%);

  .imageWrapper {
    width: 8em;
    height: 8em;
    min-width: 8em;
    margin-right: 1em;

    .imageFallback {
      background-color: #333;
      height: 100%;
    }
  }

  .newsImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .cardContent {
    h2 {
      margin: 10px 0;
    }
  }

  &:hover {
    box-shadow: 0px 0px 12px 0px rgb(97 97 97 / 45%);
  }
}

.anchor {
  color: inherit;
  text-decoration: none;
}
