@import "../../assets/styles/variables";

.MoNavContainer {
  background-color: $nav-bg;
  position: sticky;
  top: 0;
  z-index: 9;
  padding: 0 15px;
}

.mobileNav {
  overflow: hidden;
  width: 100%;
  display: flex;
  background-color: $nav-bg;
}
.navBars {
  color: white;

  img {
    width: 3em;
  }
}

.MoNavLogo {
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;

  span {
    font-size: 1.125em;
  }
}
.MoNavLogo img {
  height: 75px;
  display: block;
  margin: 0;
  padding: 6px;
}

.MoNavLinks {
  height: 0px;
  overflow: hidden;
}
.MoNavLinks ul {
  padding: 8px 60px;
  list-style-type: none;
  margin: 0;
}
.MoNavLinks li {
  height: 48px;
  padding: 10px;
  border-bottom: 1px solid #686767;
}

.MoNavLinks li a:hover {
  &::after {
    visibility: visible;
    width: 100%;
  }
}

.MoNavLinks a {
  text-decoration: none;
  display: inline-block;
  color: #fff;
  padding: 4px 0;
  letter-spacing: 2px;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    width: 0;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: $theme-hover;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }
}

.navBars {
  flex-basis: 40px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .mobileNav {
    height: 70px;
  }
  .MoNavLogo img {
    height: 65px;
  }
}

.menu {
  height: 100%;
  width: 100%;
  padding-right: 0.4em;
}


.homeLink {
  width: 100%;
  display: inherit;
  color: inherit;
  text-decoration: none;
}

.logoText {
  display: flex;
  align-self: center;
  margin-left: 1%;
  font-size: 1.5em;
  font-family: Helvetica;
  color: #fff;
}
