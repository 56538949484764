@import "../../assets/styles/mixins";

.leftCol {
  @include spacing(padding-top);
  @include spacing(padding-bottom);
}

.bannerHexagon {
  align-self: stretch;
  background: url("../../assets/icons/icon_hexagon.svg") no-repeat;
  background-position: center bottom -50px;
  background-size: 100%;
}

.whitepaperContainer {
  height: 90%;
  background: url("../../assets/icons/icon_white paper.png") no-repeat;
  background-size: contain;
  background-position: center;
}

.header {
  padding: 0.6em;
  font-size: 1.4em;
  text-transform: none;
  background-color: $theme-hover;
}

.resourceCard {
  height: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: linear-gradient(to top, #bc95cf 0%, #e4dae9 52.020199999999996%, #ffffff 100%);

  &:hover {
    box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
  }

  img {
    max-height: 10em;
    object-fit: contain;
  }

  button {
    margin: 0 auto;
  }
}
