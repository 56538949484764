@import "../../assets/styles/variables";

.bannerContainer {
  @media (min-width: 768px) {
    background: url("../../assets/icons/feature_banner_bg.png") 50%/50% no-repeat;
    background-position: 0px -80%;
    padding-top: 2em;
    padding-bottom: 2em;
  }
}

.content {
  position: relative;
  z-index: 0;

  @media (min-width: 768px) {
    &::after {
      content: "";
      height: 18em;
      width: 12em;
      position: absolute;
      right: -20px;
      top: -1em;
      background: url("../../assets/icons/home_page_icon_1.svg") center right no-repeat;
      transform: scaleX(-1);
      z-index: -3;
    }
  }
}

.usecaseContent {
  &::after {
    transform: scaleX(1);
    left: -20px;
  }
}

.imageWrapper {
  width: 8em;
  height: 8em;
  min-width: 8em;
  margin-right: 1em;

  .imageFallback {
    background-color: #333;
    height: 100%;
  }
}

.card {
  .cardContent {
    h2 {
      margin: 10px 0;
    }
  }

  &.shadow {
    padding: 1em;
    box-shadow: 0px 0px 12px 0px rgb(97 97 97 / 45%);
    background-color: #fff;
  }
}

.arrow {
  z-index: -1;
  path {
    stroke: $theme-color;
  }
}

.header {
  align-items: flex-end;
  justify-content: space-between;
}

.usecaseIcon {
  object-fit: contain;
}

.bannerImageContent {
  span {
    display: inline-block;
  }
  h6 {
    margin: 0;
  }
  .bottomIcons {
    justify-content: space-between;
  }
}
