@import "./variables";

@mixin spacing($property) {
  #{$property}: $spacing-xs;

  @media (min-width: 992px) {
    #{$property}: $spacing-md;
  }

  @media (min-width: 1200px) {
    #{$property}: $spacing-lg;
  }

  @media (min-width: 1600px) {
    #{$property}: $spacing-xl;
  }
}

.spaced-padding-left {
  @include spacing(padding-left);
}

.spaced-padding-right {
  @include spacing(padding-right);
}

.spaced-padding-vertical {
  @include spacing(padding-top);
  @include spacing(padding-bottom);
}
