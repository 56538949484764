.wrapper {
  cursor: url("../../assets/icons/icon_zoom-in.svg"), auto;
  height: 100%;
}

.content {
  text-align: center;
  margin: auto;
}

div.scrollPaper {
  background-color: transparent;
  box-shadow: none;
}

.transformWrapper {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 6em);
  width: calc(100vw - 4em);

  > div {
    width: calc(100vw - 4em);
    margin: auto;
    flex: 1 1 auto;

    > div {
      width: 100%;
      height: 100%;
    }
  }

  .tools {
    flex: 0;
  }
}

.svgWhite {
  path {
    fill: #fff;
  }
}

.closeButton {
  font-size: 2em;
  position: absolute;
  right: 0.5em;
  top: 0;
  color: #fff;
  cursor: pointer;
  padding: 0.5em;
}
