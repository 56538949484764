@import "../../assets/styles/variables";

.tile {
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
  background-color: #fff;
  padding: 1em;
  text-align: center;
  font-weight: 400;
  font-size: 0.875em;
  border-radius: 8px;
  transition: all 0.2s ease;
  flex: 1;
  height: 100%;

  &:hover {
    box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
  }
}

.tileIcon {
  width: 7em;
}
