@keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-16px);
  }
  60% {
    transform: translateX(-8px);
  }
}

@keyframes bounceLeft {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(16px);
  }
  60% {
    transform: translateX(8px);
  }
}
