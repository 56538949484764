@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.leftCol {
  @include spacing(padding-top);
  @include spacing(padding-bottom);
  // max-width: 630px;
  margin-left: auto;
  @media (min-width: 768px) {
    text-align: right;
  }
}

.bannerWrapper {
  position: relative;
  @include spacing(padding-left);
  @media (max-width: 768px) {
    @include spacing(padding-right);
  }
}

.colRightWrapper {
  height: 100%;
  background: url("../../assets/icons/features_banner.png") left top no-repeat;
  background-size: cover;
  .bannerImage {
    width: 100%;
    object-fit: contain;
  }
}

.featureHeader {
  padding: 1.17em;
  color: #000000;
  background-color: #f0edf4;
  text-align: center;
  font-size: 1.2em;
}

.featureContainer {
  @include spacing(padding-left);
  @include spacing(padding-right);
  padding-top: 1em;
}

.screenshot {
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 200px);
  object-fit: contain;
  vertical-align: bottom;
}

.featTab {
  position: relative;
}

.featMenu {
  display: flex;
  padding: 0 2em;
  background-color: $theme-color;
  color: #fff;
  a {
    color: inherit;
    text-decoration: none;
  }

  &:hover {
    background-color: lighten($theme-color, 2);
  }

  &.selectedTab {
    background-color: #fff;
    color: $text-color;
  }
}

.justifyRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}

.featureWrapper {
  p {
    max-width: 630px;
  }

  @media (max-width: 768px) {
    text-align: center;
    & button {
      margin: auto;
    }
  }
}

.arrowClass {
  padding: 0 10px;
  cursor: pointer;
  color: #fff;
  &:hover {
    font-weight: 600;
  }
  font-weight: 800;
  font-size: 2em;
}

.arrowDisabledClass {
  visibility: hidden;
}

@media only screen and (min-width: 1400px) {
  h2 {
    font-size: 2em;
  }
  h3 {
    font-size: 1.5em;
  }
}
