@keyframes rotation {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(359deg);
  }
}

.loader {
  height: calc(100vh - 150px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}
.logoBg {
  animation: rotation 8s infinite linear;
  transform-origin: center;
}
.logo {
  height: 200px;
}

.cls-1,.cls-3 {
  fill:#8865af;
}

.cls-2 {
  fill:#f7f7f7;
}

.cls-3{
  font-size:11px;font-family:Helvetica-Bold, Helvetica;font-weight:700;
}
