$body-bg: #f0edf4;
$theme-color: #8765ae;
$theme-lite: #8865af;
$theme-lite-2: #ae98c8;
$text-color: #666666;
$text-dark: #333333;
$theme-hover: #d7cdec;
$green: #39b44a;
$gradient: linear-gradient(110deg, rgba(254,189,120,1) 0%, rgba(255,145,30,1) 75%);
$nav-bg: #7450a3;
$footer-text: #cbcbcb;
$link-color: #29a9e0;
$border-color: #cacaca;
$orange: rgba(255,145,30,1);
$grey-bg: #a5a5a5;

$spacing-xs: 15px;
$spacing-md: calc((100vw - 960px) / 2);
$spacing-lg: calc((100vw - 1140px) / 2);
$spacing-xl: calc((100vw - 1540px) / 2);

$letter-spacing: 0.5px;
