@import "../../assets/styles/variables";

.whitepaper {
  background-color: $body-bg;
  padding-top: 4em;
  padding-bottom: 4em;
}

.contactInput {
  input,
  label {
    color: $text-dark !important;
  }
  fieldset {
    border: 1px solid $text-dark !important;

    legend {
      color: $text-dark !important;
    }
  }
}

.whitepaperContainer {
  height: 100%;
  background: url("../../assets/icons/icon_white paper.png") no-repeat;
  background-size: contain;
  background-position: center;
}
