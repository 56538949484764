@import "react-responsive-carousel/lib/styles/carousel.min.css";
@import "./assets/styles/styles.scss";

body {
  background-color: $body-bg;
  font-size: 14px;
  color: #333333;
  overflow-x: hidden !important;

  @media (min-width: 1200px) {
    font-size: 16px;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

p {
  font-family: 'halyard-display';
}

ul {
  padding-inline-start: 40px;
}

input {
  font-family: 'halyard-display' !important;
}
