@import "../../assets//styles/variables";

.contactInput {
  input,
  label {
    color: $text-dark !important;
  }
  fieldset {
    border: 1px solid $text-dark !important;

    legend {
      color: $text-dark !important;
    }
  }
}
