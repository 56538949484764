@import "./variables";

a, .link {
  color: $link-color;
}

.bg-theme {
  background-color: $theme-color;
}

.bg-theme-hover {
  background-color: $theme-hover;
}

.bg-white {
  background-color: #fff;
}

.color-white {
  color: #fff;
}

.color-text {
  color: $text-dark !important;
}

.color-theme {
  color: $theme-color;
}

.u-full-width {
  width: 100%;
}

.u-full-height {
  height: 100%;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left !important;
}

.u-text-right {
  text-align: right;
}

.u-text-justify {
  text-align: justify;
}

.u-font-light {
  font-weight: 400;
}

.u-font-strong {
  font-weight: 600;
}

.u-margin-bottom_2 {
  margin-bottom: 2em;
}

.u-margin-left_1 {
  margin-left: 1em;
}

.u-margin-top_1 {
  margin-top: 1em;
}

.u-margin-bottom_1 {
  margin-bottom: 1em !important;
}

.u-flex {
  display: flex;
}

.u-flex-centered {
  @extend .u-flex;
  align-items: center;
}

.u-center-justified {
  justify-content: center;
}

.u-no-padding {
  padding: 0 !important;
}

.u-no-margin {
  margin: 0;
}


.u-mt-zero {
  margin-top: 0;
}

.u-margin-auto {
  margin: auto;
}

.u-pointer {
  cursor: pointer;
}

.u-pos-rel {
  position: relative;
}

.padding-vertical_std {
  padding-top: 1em;
  padding-bottom: 1em;
}

.padding-vertical_lg {
  padding: 2.5em 0;
}


.padding-horizontal_std {
  padding-left: 2em;
  padding-right: 2em;
}

.u-margin-auto {
  margin: auto
}

.textual-button {
  @extend .u-pointer;
  border: none;
  outline: none;
  background-color: transparent;
  z-index: 3;
}

.u-line-height-std {
  line-height: 1.6;
}

.u-letter-spacing-std {
  letter-spacing: $letter-spacing;
}

.u-space-nowrap {
  white-space: nowrap;
}
