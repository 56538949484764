@import "../../assets/styles/utility";

// Overriding MUI styles in css way to avoid importing `withstyles` and other deps

.input {
  input, label {
    color: white !important;
    font-family: 'halyard-display';
  }
  fieldset {
    border: 1px solid white !important;

    legend {
      color: white !important;
    }
  }
}

.formItem:not(:last-child) {
  margin-bottom: 1.5em;
}
