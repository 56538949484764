@import "../../assets/styles/utility";
@import "../../assets/styles/mixins";
@import "../../assets/styles/variables";

.container {
  @extend .bg-theme;
  padding: 0 !important;
  word-break: break-word;
  background: #9476bc;
}

.leftCol,
.rightCol {
  padding-top: max(1em, 2.5vw);
  padding-bottom: max(1em, 2.5vw);
}

.leftCol {
  @include spacing(padding-left);
  @include spacing(padding-right);
  @extend .color-white;
  @media (max-width: 768px) {
    padding-bottom: 2.5em;
  }
}

.rightCol {
  @extend .u-flex-centered;
  @extend .u-text-center;
  @extend .color-theme;
  @include spacing(padding-right);
  padding-left: 2em;
  height: 100%;
  justify-content: space-around;
  background-color: white;
}

.arrowContainer {
  position: relative;
  left: -1px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > span {
    height: 50%;
  }

  .arrowTop {
    background: linear-gradient(to right bottom, #9476bc 50%, rgba(255, 255, 255, 0) 50%);
  }
  .arrowBottom {
    background: linear-gradient(to right top, #9476bc 50%, rgba(255, 255, 255, 0) 50%);
  }
}

.desc {
  margin-bottom: 2.5em;
  letter-spacing: $letter-spacing;
  @media (min-width: 992px) {
    width: calc(100% + #{$spacing-md} - 1em);
  }
  @media (min-width: 1200px) {
    width: calc(100% + #{$spacing-lg} - 1em);
  }
  @media (min-width: 1600px) {
    width: calc(100% + #{$spacing-xl} - 1em);
  }
}

.priceContainer {
  @extend .u-text-center;
  background-color: white;
  padding: 1em;
  color: $text-dark;
  font-size: 1.2em;
  word-break: keep-all;
}

.card {
  @extend .bg-theme;
  @extend .color-white;
  padding: 1em;
  text-align: left;
  border-radius: 8px;
  margin-bottom: 1em;

  h4 {
    margin-top: 0;

    img {
      width: 20px;
      margin-left: 8px;
      vertical-align: middle;
    }
  }

  h6 {
    margin: 0.5em 0;
  }
}
