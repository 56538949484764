@import "../../assets/styles/utility";

.selectComponent {
  background-color: transparent !important;
  border: 1px solid #fff;
  color: #fff !important;
  font-family: 'halyard-display' !important;
  font-weight: 300;
}

.formControl {
  width: 100%;
  margin-bottom: 1.5em !important;
  label {
    color: white !important;
    font-family: 'halyard-display';
  }
}
