@import "../../assets/styles/variables";
@import "../../assets/styles/utility";

.btn {
  border: none;
  outline: none;
  font-size: 1.1em;
  padding: 0.8em 1em;
  border-radius: 4px;
  cursor: pointer;
  transition: all .2s ease-in-out;
  color: #fff;
  @extend .u-flex;
  justify-content: center;

  .loader {
    @extend .u-flex-centered;
    justify-content: space-between;
  }
}

.fancyButton {
  background: rgba(255,145,30,1);
  background: $gradient;
  transition: all 280ms ease-in-out;
  &:hover {
    background-size: 100% 300%;
    transform: scale(1.05);
  }
}

.themedButton {
  background-color: $theme-color;
  transition: transform 280ms ease-in-out;
  &:hover {
    background-color: lighten($theme-color, 10);
    transform: scale(1.05);
  }
}

.largeButton {
  padding: 0.4em 0.6em;
  font-size: 1.35em;
}

.smallButton {
  padding: 0.4em 1em;
  font-size: 1em;
}
