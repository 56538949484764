@import "./variables";

.toaster > div {
  font-family: 'halyard-display';
  background-color: $theme-lite;
}

.input-error {
  position: relative;
  top: -2em;
}

.u-button-rounded {
  border-radius: 5em !important;
}
