@use "sass:math";
@import "../../assets/styles/variables";
@import "../../assets/styles/utility";
@import "../../assets/styles/mixins";

$logoContainerWidth: 10em;
$logoContainerWidth_sm: 18.2em;
$logoContainerHeight: 8em;

.leftCol {
  height: 100%;
  padding: 1em;
  display: flex;
  align-items: center;
  h1 {
    font-size: 2.5em;
  }
}

.priceCol {
  @media (min-width: 768px) {
    position: absolute;
    top: 1em;
    left: -5em;
  }

  @media (min-width: 992px) {
    top: 7em;
    left: -3em;
  }

  @media (min-width: 1200px) {
    top: 7em;
    left: -2em;
  }

  @media (min-width: 1600px) {
    top: 10em;
    left: 2em;
  }

  @media (max-width: 768px) {
    padding: 0 1em;
  }

  h3 {
    font-size: 1.1em;
  }
}

.para {
  margin: 1em 0;
  letter-spacing: 0.5px;
  font-size: 1.25em;
}

.tag {
  padding: 0.2em 0.2em;
  border-radius: 4px;
  font-size: 1.65em;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.6);
  cursor: pointer;
}

.priceTagContainer {
  position: relative;
  font-size: 1.125em;
}

.iconRight {
  position: absolute;
  left: 6em;
  top: -10px;
  height: 8em;
  width: 8em;
  z-index: -1;
}

.brandContainer {
  display: flex;
  position: relative;
  height: $logoContainerHeight;
  overflow: hidden;
  /*  @media (min-width: 992px) {
    height: $logoContainerWidth;
  } */
}

.trapezoid {
  position: absolute;
  border-left: math.div($logoContainerWidth-sm, 2) solid transparent;
  border-right: math.div($logoContainerWidth-sm, 2) solid transparent;
  height: 0;
  @media (min-width: 992px) {
    border-left: math.div($logoContainerWidth, 2) solid transparent;
    border-right: math.div($logoContainerWidth, 2) solid transparent;
  }
}

.tr1 {
  width: max(20vw, 285px);
  border-bottom: $logoContainerWidth_sm solid darken($theme-color, 10);
  left: math.div($logoContainerWidth-sm, 2);
  z-index: 2;
  filter: drop-shadow(1px 0px 4px rgba(0, 0, 0, 0.5));
  @media (min-width: 992px) {
    left: math.div($logoContainerWidth, 2);
    border-bottom: $logoContainerWidth solid darken($theme-color, 10);
  }
}

.tr2 {
  border-top: $logoContainerWidth_sm solid darken($theme-color, 5);
  width: max(38vw, 478px);
  left: 2vw;
  z-index: 1;
  @media (min-width: 992px) {
    border-top: $logoContainerWidth solid darken($theme-color, 5);
  }
}

.tr3 {
  border-bottom: $logoContainerWidth_sm solid $theme-color;
  left: 30vw;
  width: max(40vw, 530px);
  @media (min-width: 992px) {
    border-bottom: $logoContainerWidth solid $theme-color;
  }
}

.tr4 {
  border-top: $logoContainerWidth_sm solid darken($theme-color, 5);
  width: max(30vw, 372px);
  left: 60vw;
  @media (min-width: 992px) {
    border-top: $logoContainerWidth solid darken($theme-color, 5);
  }
}

.tr5 {
  border-bottom: $logoContainerWidth_sm solid darken($theme-color, 10);
  width: max(28vw, 372px);
  left: 80vw;
  @media (min-width: 992px) {
    border-bottom: $logoContainerWidth solid darken($theme-color, 10);
  }
}

.tr_white1 {
  width: max(20vw, 268px);
  border-bottom: $logoContainerWidth_sm solid darken(white, 10);
  left: math.div($logoContainerWidth-sm, 2);
  z-index: 2;
  filter: drop-shadow(1px 0px 4px rgba(0, 0, 0, 0.5));
  @media (min-width: 992px) {
    left: math.div($logoContainerWidth, 2);
    border-bottom: $logoContainerWidth solid darken(white, 10);
  }
}

.tr_white2 {
  border-top: $logoContainerWidth_sm solid darken(white, 5);
  width: max(38vw, 478px);
  left: 2vw;
  z-index: 1;
  @media (min-width: 992px) {
    border-top: $logoContainerWidth solid darken(white, 5);
  }
}

.tr_white3 {
  border-bottom: $logoContainerWidth_sm solid white;
  left: 30vw;
  width: max(40vw, 530px);
  @media (min-width: 992px) {
    border-bottom: $logoContainerWidth solid white;
  }
}

.tr_white4 {
  border-top: $logoContainerWidth_sm solid darken(white, 5);
  width: max(30vw, 372px);
  left: 60vw;
  @media (min-width: 992px) {
    border-top: $logoContainerWidth solid darken(white, 5);
  }
}

.tr_white5 {
  border-bottom: $logoContainerWidth_sm solid darken(white, 10);
  width: max(28vw, 372px);
  left: 80vw;
  @media (min-width: 992px) {
    border-bottom: $logoContainerWidth solid darken(white, 10);
  }
}

.logosContainer {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  height: $logoContainerHeight;
  position: absolute;
  top: 0;
  z-index: 5;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  @media (min-width: 768px) {
    top: 2em;

    h5 {
      margin: 0 0 1em;
      padding-left: 15px;
    }
    h2 {
      margin: 0 0 0.5em;
    }
  }

  @media (min-width: 1200px) {
    top: 1em;
  }

  @media (min-width: 1600px) {
    padding-left: $spacing-xl;
    padding-right: $spacing-xl;
  }
}

.newsLogo {
  height: 2em;
  margin: 0 0.5em;
  flex: 0 1 calc(33.33% - 1em);
  @media (min-width: 1200px) {
    height: 2.2em;
    width: 7.4em;
    flex: 0 0 7.4em;
  }
}

.highlightContainer {
  padding-top: 5vw;
  padding-bottom: 5vw;
}

.middleCol {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.ipadImg {
  z-index: 2;
  width: 100%;
  margin-top: 1.2em;
  @media (min-width: 768px) {
    margin-top: 0;
    position: relative;
    bottom: -50px;
    left: 2em;
  }
}

.certification {
  background-color: #f0edf4;
  color: $theme-color;
  padding: 2em 0;

  @media (min-width: 992px) {
    padding: 2em;
  }

  .iconContainer {
    @extend .u-flex-centered;
    justify-content: space-around;
  }

  h4 {
    margin: 0 0 0.8em;
  }

  .certLogo {
    height: 3em;
    padding: 0 2em;
  }

  .arrowClass {
    padding: 0 25px;
    cursor: pointer;
    font-weight: 800;
    font-size: 2em;
  }
}

.ipadCropped {
  width: 100%;
  margin-top: 6em;
}

.divider {
  @extend .bg-theme;
  padding: 2.5em;
}

.calendar {
  @extend .u-text-center;
  box-shadow: 0px 2px 8px 2px rgb(136 136 136 / 45%);
  padding: 3em 0;
  margin: clamp(2em, 4vw, 3.2em) 0;

  h4 {
    font-style: italic;
    margin: 0;
  }

  h1 {
    margin: 0.5em 0;
  }
}

.spaced {
  @media (min-width: 768px) {
    padding-top: max(4vw, 3em);
  }
}

.bHeading {
  color: $theme-color;
  text-align: center;

  @media (max-width: 992px) {
    top: 2em;

    h2 {
      margin-bottom: 1.2em;
    }
  }

  button {
    margin: auto;
  }
}

.ipad2 {
  height: 28em;
  object-fit: contain;
}

.innerWrapperClass {
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
}

.wrapperClass {
  width: 100%;
}
