.iconRight {
  position: absolute;
  left:7em;
  top: -12px;
  height: 8em;
  width: 8em;
  z-index: -1;
}

.pcImg {
  width: 100%;
  object-fit: contain;
}

.priceColRight {
  @media (min-width: 768px) {
    padding: 0 1em;
  }
}

.tag {
  padding: 0.2em 0.2em;
  border-radius: 4px;
  font-size: 1.65em;
  text-shadow: 0px 0px 4px rgba(255,255,255,0.6);
  cursor: pointer;
}

.priceTagContainer {
  position: relative;
  font-size: 1.125em;;
}

.bannerContainer {
  overflow: hidden;
  @media (max-width: 768px) {
    padding-bottom: 2em;
  }
}

.priceColLg {
  position: absolute;
  right: 5em;
  top: 8em;

  @media (min-width: 992px) {
    top: 11em;
  }
}
